<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="层级" prop="level">
                <a-input v-model="queryParam.level" placeholder="请输入层级" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="父级行政代码" prop="parentCode">
                <a-input v-model="queryParam.parentCode" placeholder="请输入父级行政代码" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="行政代码" prop="areaCode">
                  <a-input v-model="queryParam.areaCode" placeholder="请输入行政代码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="邮政编码" prop="zipCode">
                  <a-input v-model="queryParam.zipCode" placeholder="请输入邮政编码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="区号" prop="cityCode">
                  <a-input v-model="queryParam.cityCode" placeholder="请输入区号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="简称" prop="shortName">
                  <a-input v-model="queryParam.shortName" placeholder="请输入简称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="组合名" prop="mergerName">
                  <a-input v-model="queryParam.mergerName" placeholder="请输入组合名" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="拼音" prop="pinyin">
                  <a-input v-model="queryParam.pinyin" placeholder="请输入拼音" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['super:system:nation:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['super:system:nation:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['super:system:nation:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['super:system:nation:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :nationOptions="nationOptions" @select-tree="getTreeselect" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['super:system:nation:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['super:system:nation:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['super:system:nation:add']" />
          <a @click="$refs.createForm.handleAdd(record)" v-hasPermi="['super:system:nation:add']">
            <a-icon type="plus" />新增
          </a>
          <a-divider type="vertical" v-hasPermi="['super:system:nation:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['super:system:nation:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listNation, delNation, exportNation } from '@/api/system/nation'
import CreateForm from './modules/CreateForm'

export default {
  name: 'Nation',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      nationOptions: [],
      // 查询参数
      queryParam: {
        level: null,
        parentCode: null,
        areaCode: null,
        zipCode: null,
        cityCode: null,
        name: null,
        shortName: null,
        mergerName: null,
        pinyin: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '纬度',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '层级',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '父级行政代码',
          dataIndex: 'parentCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '行政代码',
          dataIndex: 'areaCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '邮政编码',
          dataIndex: 'zipCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '区号',
          dataIndex: 'cityCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '简称',
          dataIndex: 'shortName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组合名',
          dataIndex: 'mergerName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '拼音',
          dataIndex: 'pinyin',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询中国行政地区列表 */
    getList() {
      this.loading = true
      listNation(this.queryParam).then((response) => {
        this.list = this.handleTree(response.data, 'id', 'level')
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        level: undefined,
        parentCode: undefined,
        areaCode: undefined,
        zipCode: undefined,
        cityCode: undefined,
        name: undefined,
        shortName: undefined,
        mergerName: undefined,
        pinyin: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      listNation().then((response) => {
        this.nationOptions = []
        const data = { id: 0, name: '主目录', children: [] }
        data.children = this.handleTree(response.data, 'id', 'level')
        this.nationOptions.push(data)
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delNation(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportNation(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
